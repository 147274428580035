<template>
  <div>
    <v-dialog
      @input="dialog_closed($event)"
      scrollable
      max-width="400"
      :value="dialog"
    >
      <v-card>
        <v-card-title>
          <div>
            <h5>{{ order.length != 0 ? order[0].customer.name : "" }}</h5>
            <p class="caption">
              {{ order.length != 0 ? order[0].customer.phone : "" }}
            </p>
          </div>
          <v-spacer></v-spacer>
          <div class="text-end d-flex">
            <div class="ml-4">
              <p class="caption">
                {{ order.length != 0 ? order[0].created_at : "" }}
              </p>
            </div>
            <v-btn small @click="$emit('close_dialog')" fab>
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <!--/card title -->
        <v-card-text class="pt-5">
          <div>
            <div v-for="(alert, a) in order" :key="a">
              <v-alert
                v-if="alert.notes"
                color="#feebcb"
                class="text-center body-2"
                dense
                >{{ alert.notes }}</v-alert
              >
            </div>
          </div>
          <ul
            class="font-weight-bold"
            style="list-style: none"
            v-for="(product, p) in products"
            :key="p"
          >
            <li class="d-flex align-center justify-space-between mb-2">
              <div>
                <v-chip
                  :color="product.quantity == 1 ? 'grey' : 'yellow'"
                  label
                  class="px-1 ml-1 lighten-3"
                  small
                  >x{{ product.quantity }}</v-chip
                >
                {{ product.product_name }}
              </div>
              <div class="mr-2">({{ product.product_price }} ₪)</div>
              <div>{{ product.total }} ₪</div>
            </li>
            <li>
              <div
                class="mb-2"
                v-for="(free_add, f) in product.free_additions"
                :key="f"
              >
                {{ free_add.name }}
                <span v-if="free_add.used">({{ free_add.price }} ₪)</span>
                <span class="error--text" v-if="!free_add.used">(بدون)</span>
              </div>
            </li>

            <li>
              <div class="mb-2" v-for="(add, f) in product.additions" :key="f">
                {{ add.name }}
                <span>({{ add.price }} ₪)</span>
              </div>
            </li>
          </ul>
          <!--/ ul -->

          <div
            class="d-flex grey lighten-3 mb-4 py-2 px-3 justify-space-between"
          >
            <span>الاجمالي</span>
            <h3>{{ total_order }} ₪</h3>
          </div>
          <!--/ total -->
          <div class="d-flex justify-space-between">
            <v-btn
              @click="print_order()"
              width="33%"
              height="40"
              class="white--text"
              color="#eeb300"
            >
              <v-icon>print</v-icon>
            </v-btn>
            <v-btn
              @click="close_dialog = true"
              width="33%"
              height="40"
              class="white--text"
              color="error"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-btn
              @click="done_dialog = true"
              width="33%"
              height="40"
              class="white--text"
              color="success"
            >
              <v-icon>check</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <!--/card text -->
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="done_dialog">
      <v-card>
        <v-card-text class="pt-10 pb-10">
          <h2 class="text-center">تم الانتهاء من الطاولة ؟</h2>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="success" @click="order_done()">تأكيد</v-btn>
          <v-btn text @click="close_done_dialog()">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="close_dialog">
      <v-card>
        <v-card-text class="pt-10 pb-10">
          <h2 class="text-center">
            الغاء الطاولة ؟ <v-icon color="error">cancel</v-icon>
          </h2>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" @click="order_cancel()">نعم</v-btn>
          <v-btn text @click="close_dialog = false">لا</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "LocalOrder",
  props: ["dialog", "order"],
  data() {
    return {
      close_dialog: false,
      products: [],
      done_dialog: false,
      item_done: null,
    };
  },
  watch: {
    order(val) {
      if (val.length != 0) {
        this.order.forEach((e, i) => {
          e.products.forEach((p, x) => {
            this.products.push(p);
          });
        });
      } else {
        this.products = Object.assign([]);
      }
    },
  },
  computed: {
    total_order() {
      let total = 0;
      this.order.forEach((e) => {
        total += e.total;
      });
      return total;
    },
  },
  methods: {
    dialog_closed(ev) {
      if (!ev) {
        this.$emit("close_dialog");
      }
    },
    print_order() {
      let order = {
        customer: this.order[0].customer,
        created_at: this.order[0].created_at,
        products: this.products,
        total: this.total_order,
        is_local: true,
      };
      this.$emit("print_order", order);
    },
    close_done_dialog() {
      this.item_done = null;
      this.done_dialog = false;
    },
    order_done() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "table_done",
            method: "post",
          },
          data: {
            table_code: this.order[0].table_code,
          },
        })
        .then((res) => {
          console.log(res);
          this.$emit("update_oreders");
          this.dialog_closed();
          this.close_done_dialog();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    order_cancel() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "table_cancel",
            method: "post",
          },
          data: {
            table_code: this.order[0].table_code,
          },
        })
        .then((res) => {
          console.log(res);
          this.$emit("update_oreders");
          this.dialog_closed();
          this.close_dialog= false
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  padding-bottom: 15px !important;
  &:first-of-type {
    margin-top: 25px;
  }
  &:not(:last-of-type) {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }

  // padding-bottom:15px;
}
</style>
