<template>
  <div>
    <div id="print_reset" class="d-none">
      <div class="body">
        <header>
          <div>
            <h5>{{ order.customer.name }}</h5>
            <p class="caption">{{ order.customer.phone }}</p>
          </div>

          <div>
            <h5 v-if="!order.is_local" class="order_id">#{{ order.id }}</h5>
            <p>{{ order.created_at }}</p>
          </div>
        </header>

        <section>
          <ul v-for="(product, p) in order.products" :key="p">
            <li>
              <div>
                <span class="chip"> x{{ product.quantity }} </span>
                {{ product.product_name[0].name }}
              </div>

              <div v-if="!is_filter">({{ product.product_price }} ₪)</div>

              <div v-if="!is_filter">({{ product.total }} ₪)</div>
            </li>

            <li>
              <div v-for="(free_add, f) in product.free_additions" :key="f">
                {{ free_add.name }}
                <span v-if="free_add.used && !is_filter"
                  >( {{ free_add.price }} ₪)</span
                >
                <span v-if="!free_add.used" class="error">(بدون)</span>
              </div>
            </li>

            <li>
              <div v-for="(add, f) in product.additions" :key="f">
                <span class="chip"> x{{ add.quantity }} </span>
                {{ add.name }}
                <span v-if="!is_filter"
                  >({{ add.is_free ? 0 : add.price }} ₪)</span
                >
              </div>
            </li>
          </ul>

          <div class="total" v-if="!is_filter">
            <span>الاجمالي</span>
            <h3>{{ order.total }} ₪</h3>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Print",
  props: ["order"],
  data() {
    return {};
  },
  computed: {
    is_filter() {
      return this.$route.name == "cube";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .card {
    background-color: red;
  }
}
</style>
