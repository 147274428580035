<template>
  <v-container class="orders">
    <OrderFilter
      ref="orders_filter"
      @update_oreders="get_orders()"
      v-if="is_filter"
      :cat_id="cat_id"
      @get_orders="get_orders"
      @update_filter_orders="update_filter_orders($event)"
    ></OrderFilter>

    <OrderTabs
      @change_tab="($event) => (tabs = $event)"
      :tabs="tabs"
      :stats="stats"
    ></OrderTabs>

    <Orders
      @print_order="print_order($event)"
      @update_oreders="get_orders()"
      :orders="orders"
      :tabs="tabs"
    ></Orders>

    <Print :order="p_order"></Print>
  </v-container>
</template>

<script>
import OrderFilter from "@/components/orders/OrderFilter";

import OrderTabs from "@/components/orders/tabs";
import Orders from "@/components/orders/orders";
import Print from "@/components/orders/OrderPrint";

export default {
  name: "daily_orders",
  data() {
    return {
      menu1: false,
      cat_id: [],
      tabs: 1,
      order: {
        customer: {
          name: "",
          phone: "",
        },
        products: [],
      },
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      orders: {
        done: [],
        new: [],
        preparation: [],
        prepared: [],
        local: [],
      },
    };
  },
  components: {
    OrderTabs,
    Orders,
    Print,
    OrderFilter,
  },
  computed: {
    p_order() {
      return this.order != null ? this.order : null;
    },
    is_filter() {
      return this.$route.name == "cube";
    },
    stats() {
      return {
        new: this.orders.new.length,
        preparation: this.orders.preparation.length,
        prepared: this.orders.prepared.length,
        local: this.orders.local.length,
      };
    },
  },
  methods: {
    get_orders() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "orders",
          },
        })
        .then((res) => {
          this.orders = Object.assign({}, res.data.data);
        });
    },
    print_order(ev) {
      this.order = Object.assign({}, ev);
      setTimeout(() => {
        this.$htmlToPaper("print_reset");
      });
    },
    update_filter_orders(ev) {
      this.orders = Object.assign([], ev);
    },
  },
  created() {
    let i = 0;

    window.onmousemove = function () {
      i = 0;
    };

      this.$store.state.live_interval = setInterval(() => {
        i++;
        console.log(i);
        if (i >= 30) {
          if (this.is_filter) {
            if (this.$refs.orders_filter.cat_id.length != 0) {
              this.$refs.orders_filter.filter_orders();
            } else {
              this.get_orders();
            }
          } else {
            this.get_orders();
          }
          i = 0;
        }
      }, 1000);
  },
  mounted() {
    this.get_orders();
  },
};
</script>
