<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        :class="{ 'd-block': tabs == 1 }"
        :sm="
          $store.getters.permissions.includes('tables') && !is_filter
            ? '3'
            : '4'
        "
        class="px-1 tab"
      >
        <v-alert
          color="#e8e8e8"
          v-if="orders.new.length == 0"
          class="text-center"
        >
          لايوجد طلبات
        </v-alert>
        <div class="order_cards">
          <v-card
            class="mb-5"
            :id="`order_${order.id}`"
            v-for="(order, i) in orders.new"
            :key="i"
          >
            <v-card-title>
              <div>
                <h5>{{ order.customer.name }}</h5>
                <p class="caption">{{ order.customer.phone }}</p>
              </div>
              <v-spacer></v-spacer>
              <div class="text-end">
                <h5>#{{ order.id }}</h5>
                <p class="caption">{{ order.created_at }}</p>
              </div>
            </v-card-title>
            <!--/card title -->
            <v-card-text>
              <v-alert
                v-if="order.notes"
                color="#feebcb"
                class="text-center body-2"
                dense
                >{{ order.notes }}</v-alert
              >
              <ul
                class="font-weight-bold pa-0"
                style="list-style: none"
                v-for="(product, p) in order.products"
                :key="p"
              >
                <li class="mb-2">
                  <v-btn
                    v-if="!is_filter"
                    @click="show_delete_product_dialog(product)"
                    class="mb-2"
                    small
                    color="error"
                  >
                    حذف المنتج
                  </v-btn>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <v-chip
                        :color="product.quantity == 1 ? 'grey' : 'yellow'"
                        label
                        class="px-1 ml-1 lighten-3"
                        small
                        >x{{ product.quantity }}</v-chip
                      >
                      <span>
                        {{ product.product_name[0].name }}
                      </span>
                    </div>
                    <div v-if="!is_filter" class="mr-2">
                      ({{ product.product_price }} ₪)
                    </div>
                    <div v-if="!is_filter">{{ product.total }} ₪</div>
                  </div>
                </li>

                <li>
                  <div
                    class="mb-2 d-flex align-center justify-space-between"
                    v-for="(free_add, f) in product.free_additions"
                    :key="f"
                  >
                    <p>
                      {{ free_add.name }}
                      <span v-if="free_add.used && !is_filter"
                        >({{ free_add.price }} ₪)</span
                      >
                      <span class="error--text" v-if="!free_add.used"
                        >(بدون)</span
                      >
                    </p>

                    <v-btn
                      v-if="!is_filter"
                      @click="
                        show_delete_add_dialog(product.id, free_add.table_id)
                      "
                      icon
                      small
                      color="error"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </div>
                </li>

                <li>
                  <div
                    class="mb-2 d-flex align-center justify-space-between"
                    v-for="(add, f) in product.additions"
                    :key="f"
                  >
                    <p class="d-flex">
                      {{ add.name }}
                      <span v-if="!is_filter"
                        >({{ add.is_free ? 0 : add.price }} ₪)</span
                      >
                    </p>
                    <v-btn
                      v-if="!is_filter"
                      @click="show_delete_add_dialog(product.id, add.table_id)"
                      icon
                      small
                      color="error"
                    >
                      <v-icon>close </v-icon>
                    </v-btn>
                  </div>
                </li>
              </ul>
              <!--/ ul -->

              <div
                v-if="!is_filter"
                class="d-flex grey lighten-3 mb-4 py-2 px-3 justify-space-between"
              >
                <span>الاجمالي</span>
                <h3>{{ order.total }} ₪</h3>
              </div>
              <!--/ total -->
              <div class="d-flex justify-space-between">
                <v-btn
                  @click="print_order(order)"
                  width="45%"
                  height="40"
                  class="white--text"
                  color="#eeb300"
                >
                  <v-icon>print</v-icon>
                </v-btn>
                <v-btn
                  v-if="!is_filter"
                  @click="(cancel = true), show_dialog(order)"
                  width="45%"
                  height="40"
                  class="white--text"
                  color="#cd0029"
                >
                  <v-icon>cancel</v-icon>
                </v-btn>
              </div>
              <v-btn
                v-if="!is_filter"
                @click="show_dialog(order)"
                label
                width="100%"
                class="mt-3 white--text font-weight-bold subtitle-1"
                height="80"
                color="#644ae2"
                >بدء التحضير</v-btn
              >
            </v-card-text>
            <!--/card text -->
          </v-card>
        </div>
      </v-col>

      <v-col
        cols="12"
        :class="{ 'd-block': tabs == 2 }"
        :sm="
          $store.getters.permissions.includes('tables') && !is_filter
            ? '3'
            : '4'
        "
        class="px-1 tab"
      >
        <v-alert
          color="#e8e8e8"
          v-if="orders.preparation.length == 0"
          class="text-center"
        >
          لايوجد طلبات
        </v-alert>
        <div class="order_cards">
          <v-card
            class="mb-5"
            v-for="(order, i) in orders.preparation"
            :key="i"
          >
            <v-card-title>
              <div>
                <h5>{{ order.customer.name }}</h5>
                <p class="caption">{{ order.customer.phone }}</p>
              </div>
              <v-spacer></v-spacer>
              <div class="text-end">
                <h5>#{{ order.id }}</h5>
                <p class="caption">{{ order.created_at }}</p>
              </div>
            </v-card-title>
            <!--/card title -->
            <v-card-text>
              <v-alert
                v-if="order.notes"
                color="#feebcb"
                class="text-center body-2"
                dense
                >{{ order.notes }}</v-alert
              >

              <div class="d-flex justify-end">
                <span
                  v-if="
                    order.time_down.min != 0 && order.time_down.seconds != 0
                  "
                >
                  متبقي</span
                >
                <h3
                  class="red--text ms-2"
                  v-if="
                    order.time_down.seconds != 0 || order.time_down.min != 0
                  "
                >
                  {{ order.time_down.seconds }} : {{ order.time_down.min }}
                </h3>
                <h3 class="success--text ms-2" v-else>تم التجهيز</h3>
              </div>
              <ul
                class="font-weight-bold pe-2 mt-2"
                style="list-style: none"
                v-for="(product, p) in order.products"
                :key="p"
              >
                <li class="mb-2">
                  <div class="d-flex justify-space-between">
                    <v-btn
                      v-if="!is_filter"
                      @click="show_delete_product_dialog(product)"
                      class="mb-2"
                      small
                      color="error"
                    >
                      حذف المنتج
                    </v-btn>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <v-chip
                        :color="product.quantity == 1 ? 'grey' : 'yellow'"
                        label
                        class="px-1 ml-1 lighten-3"
                        small
                        >x{{ product.quantity }}</v-chip
                      >
                      <span>
                        {{ product.product_name[0].name }}
                      </span>
                    </div>
                    <div v-if="!is_filter" class="mr-2">
                      ({{ product.product_price }} ₪)
                    </div>
                    <div v-if="!is_filter">{{ product.total }} ₪</div>
                  </div>
                </li>

                <li>
                  <div
                    class="mb-2 d-flex align-center justify-space-between"
                    v-for="(free_add, f) in product.free_additions"
                    :key="f"
                  >
                    <p>
                      {{ free_add.name }}
                      <span v-if="free_add.used && !is_filter"
                        >({{ free_add.price }} ₪)</span
                      >
                      <span class="error--text" v-if="!free_add.used"
                        >(بدون)</span
                      >
                    </p>

                    <v-btn
                      v-if="!is_filter"
                      @click="
                        show_delete_add_dialog(product.id, free_add.table_id)
                      "
                      icon
                      small
                      color="error"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </div>
                </li>

                <li>
                  <div
                    class="mb-2 d-flex align-center justify-space-between"
                    v-for="(add, f) in product.additions"
                    :key="f"
                  >
                    <p class="d-flex">
                      <v-chip
                        :color="add.quantity == 1 ? 'grey' : 'yellow'"
                        label
                        class="px-1 ml-2 lighten-3"
                        small
                        >x{{ add.quantity }}</v-chip
                      >
                      {{ add.name }}
                      <span v-if="!is_filter"
                        >({{ add.is_free ? 0 : add.price }} ₪)</span
                      >
                    </p>
                    <v-btn
                      v-if="!is_filter"
                      @click="show_delete_add_dialog(product.id, add.table_id)"
                      icon
                      small
                      color="error"
                    >
                      <v-icon>close </v-icon>
                    </v-btn>
                  </div>
                </li>
              </ul>
              <!--/ ul -->

              <div
                v-if="!is_filter"
                class="d-flex grey lighten-3 mb-4 py-2 px-3 justify-space-between"
              >
                <span>الاجمالي</span>
                <h3>{{ order.total }} ₪</h3>
              </div>
              <!--/ total -->
              <div class="d-flex justify-space-between">
                <v-btn
                  @click="print_order(order)"
                  width="45%"
                  height="40"
                  class="white--text"
                  color="#eeb300"
                >
                  <v-icon>print</v-icon>
                </v-btn>
                <v-btn
                  v-if="!is_filter"
                  @click="(cancel = true), show_dialog(order)"
                  width="45%"
                  height="40"
                  class="white--text"
                  color="#cd0029"
                >
                  <v-icon>error</v-icon>
                </v-btn>
              </div>
              <v-btn
                v-if="!is_filter"
                @click="show_dialog(order)"
                label
                width="100%"
                class="mt-3 white--text font-weight-bold subtitle-1"
                height="80"
                color="#000"
                >تم التجهيز</v-btn
              >
            </v-card-text>
            <!--/card text -->
          </v-card>
        </div>
      </v-col>

      <v-col
        cols="12"
        :class="{ 'd-block': tabs == 3 }"
        :sm="
          $store.getters.permissions.includes('tables') && !is_filter
            ? '3'
            : '4'
        "
        class="px-1 tab"
      >
        <v-alert
          color="#e8e8e8"
          v-if="orders.prepared.length == 0"
          class="text-center"
        >
          لايوجد طلبات
        </v-alert>
        <div class="order_cards">
          <v-card class="mb-5" v-for="(order, i) in orders.prepared" :key="i">
            <v-card-title>
              <div>
                <h5>{{ order.customer.name }}</h5>
                <p class="caption">{{ order.customer.phone }}</p>
              </div>
              <v-spacer></v-spacer>
              <div class="text-end">
                <h5>#{{ order.id }}</h5>
                <p class="caption">{{ order.created_at }}</p>
              </div>
            </v-card-title>
            <!--/card title -->
            <v-card-text>
              <v-alert
                v-if="order.notes"
                color="#feebcb"
                class="text-center body-2"
                dense
                >{{ order.notes }}</v-alert
              >
              <ul
                class="font-weight-bold"
                style="list-style: none"
                v-for="(product, p) in order.products"
                :key="p"
              >
                <li class="mb-2">
                  <v-btn
                    v-if="!is_filter"
                    @click="show_delete_product_dialog(product)"
                    class="mb-2"
                    small
                    color="error"
                  >
                    حذف المنتج
                  </v-btn>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <v-chip
                        :color="product.quantity == 1 ? 'grey' : 'yellow'"
                        label
                        class="px-1 ml-1 lighten-3"
                        small
                        >x{{ product.quantity }}</v-chip
                      >
                      <span>
                        {{ product.product_name[0].name }}
                      </span>
                    </div>
                    <div v-if="!is_filter" class="mr-2">
                      ({{ product.product_price }} ₪)
                    </div>
                    <div v-if="!is_filter">{{ product.total }} ₪</div>
                  </div>
                </li>

                <li>
                  <div
                    class="mb-2 d-flex align-center justify-space-between"
                    v-for="(free_add, f) in product.free_additions"
                    :key="f"
                  >
                    <p>
                      {{ free_add.name }}
                      <span v-if="free_add.used && !is_filter"
                        >({{ free_add.price }} ₪)</span
                      >
                      <span class="error--text" v-if="!free_add.used"
                        >(بدون)</span
                      >
                    </p>

                    <v-btn
                      v-if="!is_filter"
                      @click="
                        show_delete_add_dialog(product.id, free_add.table_id)
                      "
                      icon
                      small
                      color="error"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </div>
                </li>

                <li>
                  <div
                    class="mb-2 d-flex align-center justify-space-between"
                    v-for="(add, f) in product.additions"
                    :key="f"
                  >
                    <p class="d-flex">
                      <v-chip
                        :color="add.quantity == 1 ? 'grey' : 'yellow'"
                        label
                        class="px-1 ml-2 lighten-3"
                        small
                        >x{{ add.quantity }}</v-chip
                      >
                      {{ add.name }}
                      <span v-if="!is_filter"
                        >({{ add.is_free ? 0 : add.price }} ₪)</span
                      >
                    </p>
                    <v-btn
                      v-if="!is_filter"
                      @click="show_delete_add_dialog(product.id, add.table_id)"
                      icon
                      small
                      color="error"
                    >
                      <v-icon>close </v-icon>
                    </v-btn>
                  </div>
                </li>
              </ul>
              <!--/ ul -->

              <div
                v-if="!is_filter"
                class="d-flex grey lighten-3 mb-4 py-2 px-3 justify-space-between"
              >
                <span>الاجمالي</span>
                <h3>{{ order.total }} ₪</h3>
              </div>
              <!--/ total -->
              <div class="d-flex justify-space-between">
                <v-btn
                  @click="print_order(order)"
                  width="45%"
                  height="40"
                  class="white--text"
                  color="#eeb300"
                >
                  <v-icon>print</v-icon>
                </v-btn>
                <v-btn
                  v-if="!is_filter"
                  @click="(cancel = true), show_dialog(order)"
                  width="45%"
                  height="40"
                  class="white--text"
                  color="#cd0029"
                >
                  <v-icon>error</v-icon>
                </v-btn>
              </div>
              <v-btn
                v-if="!is_filter"
                @click="show_dialog(order)"
                color="success"
                label
                width="100%"
                class="mt-3 white--text font-weight-bold subtitle-1"
                height="80"
                >تم التسليم</v-btn
              >
            </v-card-text>
            <!--/card text -->
          </v-card>
        </div>
      </v-col>

      <v-col
        :class="{ 'd-block': tabs == 4 }"
        v-if="$store.getters.permissions.includes('tables') && !is_filter"
        cols="12"
        sm="3"
        class="px-1 tab"
      >
        <v-alert
          color="#e8e8e8"
          v-if="orders.local.length == 0"
          class="text-center"
        >
          لايوجد طلبات
        </v-alert>
        <v-list dens>
          <v-list-item
            class="grey lighten-3 mb-3"
            @click="show_order(order)"
            v-for="(order, ind) in orders.local"
            :key="ind"
          >
            <v-list-item-content>
              <div class="d-flex align-center justify-space-between">
                <span class="caption font-weight-bold">{{
                  order[0].created_at
                }}</span>
                <span class="caption">{{ order[0].table_name }}</span>
              </div>
              <div class="d-flex align-center justify-space-between">
                <h4>{{ order[0].customer.name }}</h4>
                <v-chip color="success">{{ order.length }}</v-chip>
              </div>
              <h4 class="body-2">الأجمالي ({{ total_local[ind] }} ₪)</h4>
            </v-list-item-content>
            <!--/content -->
          </v-list-item>
        </v-list>
        <!--/list -->
      </v-col>
    </v-row>

    <v-dialog max-width="500" v-model="dialog">
      <v-card>
        <v-card-text class="pt-10 pb-10">
          <h2 class="text-center">
            {{ dialog_text }}
            <v-icon v-if="cancel" color="error">cancel</v-icon>
          </h2>
          <v-form ref="form" v-if="in_progress && !cancel">
            <v-select
              v-model="time_select"
              v-if="time_select != 'other'"
              class="mt-10"
              solo
              item-text="text"
              item-value="value"
              label="وقت التحضير"
              :items="times"
              hide-details
              @change="check_time_down($event)"
            ></v-select>

            <v-text-field
              class="mt-10"
              v-if="time_select == 'other'"
              type="number"
              hide-details
              v-model.number="time_down"
              solo
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn :color="cancel ? 'error' : 'success'" @click="change_status()"
            >تأكيد</v-btn
          >
          <v-btn :color="cancel ? '' : 'error'" text @click="close_dialog()"
            >الغاء</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="1000" left v-model="snackbar">
      <p>تم تحديث حالة الطلب</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check</v-icon>
      </template>
    </v-snackbar>

    <LocalOrder
      @update_oreders="$emit('update_oreders')"
      @print_order="$emit('print_order', $event)"
      @close_dialog="close_local_order()"
      :dialog="dialog_order"
      :order="item"
    ></LocalOrder>

    <!-- delete product -->
    <v-dialog max-width="400" v-model="delete_product_dialog">
      <v-card>
        <v-card-text class="pt-10 pb-10">
          <h2 class="text-center">
            حذف الوجبة ؟
            <v-icon v-if="cancel" color="error">cancel</v-icon>
          </h2>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" @click="delete_product()">تأكيد</v-btn>
          <v-btn text @click="delete_product_dialog = false">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- delete additions -->
    <v-dialog max-width="400" v-model="delete_addition_dialog">
      <v-card>
        <v-card-text class="pt-10 pb-10">
          <h2 class="text-center">
            حذف الأضافة ؟
            <v-icon v-if="cancel" color="error">cancel</v-icon>
          </h2>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" @click="delete_addition()">تأكيد</v-btn>
          <v-btn text @click="delete_addition_dialog = false">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LocalOrder from "@/components/orders/local_order";
import { parse } from "path";
export default {
  name: "Orders",
  props: ["orders", "tabs"],
  data() {
    return {
      is_mobile: false,
      product: null,
      delete_product_dialog: false,
      addition_id: null,
      product_id: null,
      delete_addition_dialog: false,
      dialog: false,
      cancel: false,
      snackbar: false,
      dialog_order: false,
      order_item: {},
      dialog_text: "",
      time_down: null,
      in_progress: false,
      item: [],
      time_select: 0,
      seconds: 59,
      times: [
        {
          value: 0,
          text: "بدون وقت",
        },
        {
          value: 5,
          text: "5 دقائق",
        },
        {
          value: 10,
          text: "10 دقائق",
        },
        {
          value: 15,
          text: "15 دقائق",
        },
        {
          value: 20,
          text: "20 دقائق",
        },
        {
          value: "other",
          text: "وقت اخر",
        },
      ],
    };
  },
  watch: {
    dialog(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.close_dialog();
      }
    },
  },
  components: {
    LocalOrder,
  },
  computed: {
    is_filter() {
      return this.$route.name == "cube";
    },
    total_local() {
      let total = [];
      this.orders.local.forEach((e, i) => {
        let local_total = 0;
        e.forEach((t) => {
          local_total += t.total;
        });
        total.push(local_total);
      });
      return total;
    },
    times_props() {
      return this;
    },
  },
  methods: {
    close_local_order() {
      this.dialog_order = false;
      this.item = Object.assign([]);
    },
    print_order(order) {
      this.$emit("print_order", order);
    },
    show_dialog(item) {
      this.order_item = Object.assign({}, item);

      if (this.cancel) {
        this.dialog_text = "تأكيد حذف الطلب";
      } else {
        if (item.status == 0) {
          this.dialog_text = "الطلب قيد التحضر ؟ ";
          this.in_progress = true;
        } else if (item.status == 1) {
          this.dialog_text = "تم تجهيز الطلب ؟";
          this.in_progress = false;
        } else {
          this.dialog_text = "تم تسليم الطلب ؟ ";
          this.in_progress = false;
        }
      }

      this.dialog = true;
    },
    change_status() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `orders/${this.order_item.id}`,
            method: "put",
          },
          data: {
            status: this.cancel ? 4 : this.order_item.status + 1,
            time_down: this.time_down,
          },
        })
        .then((res) => {
          this.snackbar = true;
          this.$emit("update_oreders");
          this.time_down = null;
          this.close_dialog();
        })
        .catch((err) => {
          this.time_down = null;
          console.log(err.response);
        });
    },
    close_dialog() {
      this.order_item = Object.assign({});
      this.dialog = false;
      this.cancel = false;
    },
    show_order(item) {
      this.item = item;
      this.dialog_order = true;
    },
    show_delete_product_dialog(product) {
      this.product = product;
      this.delete_product_dialog = true;
    },
    delete_product() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: `remove_product/${this.product.id}`,
            method: "delete",
          },
        })
        .then((res) => {
          this.$emit("update_oreders");
          this.delete_product_dialog = false;
          this.product = null;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    show_delete_add_dialog(product_id, addition_id) {
      this.product_id = product_id;
      this.addition_id = addition_id;
      this.delete_addition_dialog = true;
    },
    delete_addition() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "remove_addition",
            method: "post",
          },
          data: {
            product_id: this.product_id,
            addition_id: this.addition_id,
          },
        })
        .then((res) => {
          console.log(res);
          this.$emit("update_oreders");
          this.delete_addition_dialog = false;
          this.addition_id = null;
          this.product_id = null;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    check_time_down(ev) {
      if (ev != "other") {
        this.time_down = ev;
      }
    },
  },
  created() {
    let winX = window.innerWidth;
    if (winX <= 767) {
      this.is_mobile = true;
    }

    let vm = this;
    setInterval(() => {
      vm.orders.preparation.forEach((e, i) => {
        let seconds = parseInt(this.orders.preparation[i].time_down.seconds);
        let min = parseInt(this.orders.preparation[i].time_down.min);
        if (min <= 0 && seconds <= 0) {
          this.order_item = this.orders.preparation[i];
          this.change_status();
        } else {
          if (seconds <= 1 && min != 0) {
            this.orders.preparation[i].time_down.seconds = 59;
            this.orders.preparation[i].time_down.min =
              min-- <= 10 ? `0${min}` : min;
          } else {
            this.orders.preparation[i].time_down.seconds =
              seconds-- <= 10 ? `0${seconds--}` : seconds--;
          }
        }
      });
    }, 1000);
  },
};
</script>

<style id="style" scoped lang="scss">
.order_cards {
  height: calc(100vh - 250px);
  overflow-y: scroll;
}
ul {
  padding-bottom: 15px !important;
  &:first-of-type {
    margin-top: 25px;
  }
  &:not(:last-of-type) {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
}

@media (max-width: 757px) {
  .tab {
    display: none;
  }
}
</style>
