<template>
  <header class="tabs">
    <v-row>
      <v-col
        :cols="
          $store.getters.permissions.includes('tables') && !is_filter
            ? '3'
            : '4'
        "
        class="px-1"
      >
        <v-btn
          @click="change_tab(1)"
          color="#e58200"
          width="100%"
          height="65"
          class="white--text font-weight-bold"
        >
          <div
            style="width: 100%"
            class="d-sm-flex justify-space-between align-center tab_title"
          >
            <h2>{{ stats.new }}</h2>
            طلبات جديدة
          </div>
        </v-btn>
      </v-col>
      <v-col
        :cols="
          $store.getters.permissions.includes('tables') && !is_filter
            ? '3'
            : '4'
        "
        class="px-1"
      >
        <v-btn
          @click="change_tab(2)"
          class="d-flex justify-space-between white--text font-weight-bold"
          color="#644ae1"
          width="100%"
          height="65"
        >
          <div
            style="width: 100%"
            class="d-sm-flex justify-space-between align-center text-center tab_title"
          >
            <h2>{{ stats.preparation }}</h2>
            قيد التحضير
          </div>
        </v-btn>
      </v-col>
      <v-col
        :cols="
          $store.getters.permissions.includes('tables') && !is_filter
            ? '3'
            : '4'
        "
        class="px-1"
      >
        <v-btn
          @click="change_tab(3)"
          class="d-flex px-2 justify-space-between font-weight-bold"
          width="100%"
          height="65"
        >
          <div
            style="width: 100%"
            class="d-sm-flex text-center align-center justify-space-between tab_title"
          >
            <h2>{{ stats.prepared }}</h2>
            جاهز للأستلام
          </div>
        </v-btn>
      </v-col>

      <v-col
        v-if="$store.getters.permissions.includes('tables') && !is_filter"
        cols="3"
        class="px-1"
      >
        <v-btn
          @click="change_tab(4)"
          class="d-flex justify-space-between font-weight-bold"
          width="100%"
          height="65"
          color="primary"
        >
          <div
            style="width: 100%"
            class="d-sm-flex justify-space-between align-center tab_title"
          >
            <h2>{{ stats.local }}</h2>
            الطاولات
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </header>
</template>

<script>
export default {
  name: "OrderTabs",
  props: ["stats"],
  data() {
    return {
      tabs_width: 0,
    };
  },
  computed: {
    is_filter() {
      return this.$route.name == "cube";
    },
  },
  methods:{
    change_tab(ev) {
        this.$emit("change_tab", ev);
    }
  },
  mounted() {
    // let wrap_with = document.querySelector(".container").offsetWidth - 104;
    // let tabs = document.querySelector(".tabs");
    // tabs.style.width = wrap_with+'px';
    // console.log(wrap_with)
    // window.on
  },
};
</script>

<style scoped lang="scss">
.tab_title {
  @media(max-width:580px) {
    font-size:12px;
  }
}
.tabs {
  // position: fixed;
  left: 69px;
  top: 180px;
  z-index: 1000;
  // background-color:#ddd;
  padding: 20px 0;
}
</style>
>
