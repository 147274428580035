<template>
  <div>
    <v-form ref="form">
      <v-row>
        <v-col cols="6" sm="4">
          <v-select
            item-value="id"
            item-text="category_name[0].name"
            :items="categories"
            :disabled="!data_loaded"
            :loading="!data_loaded"
            :rules="[(v) => !!v || 'مطلوب']"
            solo
            multiple
            label="القسم"
            @change="get_products(), update_filter()"
            v-model="cat_id"
          ></v-select>
        </v-col>
        <!--/ col -->
        <v-col cols="6" sm="4">
          <v-select
            v-model="meals_ids"
            @change="update_filter()"
            solo
            label="الوجبة"
            item-value="id"
            item-text="trans_name[0].name"
            :items="products"
            multiple
            hide-details
          ></v-select>
        </v-col>
        <!--/ col -->
      </v-row>
    </v-form>

    <!--/ row -->
  </div>
</template>

<script>
export default {
  name: "OrderFilter",
  data() {
    return {
      data_loaded: false,
      cat_id: [],
      products: [],
      categories: [],
      meals_ids: [],
    };
  },
  methods: {
    get_categories() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "Categories",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.categories = Object.assign([], res.data.data);
        });
    },
    get_products() {
      this.meals_ids = null;
      if (this.cat_id.length != 0) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `get_products`,
              method: "post",
            },
            data: {
              cats_ids: this.cat_id,
            },
          })
          .then((res) => {
            this.products = Object.assign([], res.data.data);
          });
      }
    },
    filter_orders() {
      if (this.cat_id.length != 0) {
        this.$store
          .dispatch("public__request", {
            config: {
              url: `cubes`,
              method: "post",
            },
            data: {
              cat_ids: this.cat_id,
              meals_ids: this.meals_ids,
            },
          })
          .then((res) => {
            console.log(res);
            this.data_loaded = true;
            this.$emit("update_filter_orders", res.data.data);
          })
          .catch((err) => {
            console.log(err.response);
          });
      } else {
        this.$emit("get_orders");
      }
    },
    update_filter() {
      if (this.$refs.form.validate()) {
        this.filter_orders();
      }
    },
  },
  created() {
    this.get_categories();
  },
};
</script>
